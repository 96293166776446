<template>
  <div class="container">
    <div v-if="loadingPage" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>
    <div v-else class="written-exam-courses-list mt-4">
      <h4 class="text-center">Список курсов (вид экзамена: тест)</h4>


      <!-- Table Start -->
      <DataTable :value="courses" :paginator="true" :rows="50"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[50, 100, 200]"
                 filterDisplay="menu"
                 :globalFilterFields="['course_name']"
                 v-model:filters="filters"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск курса"/>
              </span>
          </div>
        </template>


        <Column header="Название курса">
          <template #body="{data}">
            <a :href="'https://moodle.uib.kz/course/view.php?id='+ data.mdl_course_id" target="_blank">{{data.course_name}}</a>
          </template>
        </Column>

        <Column field="semester" header="Семестр"></Column>
        <Column field="language" header="Язык обучения"></Column>
        <Column header="Лектор">
          <template #body="{data}">
            {{ data.lecture_lastname }} {{ data.lecture_firstname }}
          </template>
        </Column>




      </DataTable>
      <!-- Table End -->


    </div>


  </div>
</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import httpClient from "../../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";


export default {
  name: "TestExamCoursesList",
  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'course_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },

      loadingPage: true,
      courses: []
    }
  },
  computed: {
    //...mapState('writtenExamCoursesList', ['writtenExamCoursesList_form']),
  },

  methods: {
    //...mapActions('writtenExamCoursesList', ['GET_EDUCATION_COURSES_WRITTEN_EXAM', 'GET_EXAM_QUESTIONS_SEND_TO_PRINT']),

    async GET_EDUCATION_COURSES_TEST_EXAM() {
      try {
        let url = 'education_program/education-courses/get-test-exam-courses' + '?access-token=' + getCookie('ACCESS_TOKEN')

        const {
          status,
          data
        } = await httpClient.get(url);
        if (status === 200) {
          this.courses = data
          console.log(this.courses, 'courses')
        }
      } catch (e) {
        console.log(e.response);
        return {
          data: false,
          error: e.response.data.errors
        }
      }
    },


  },
  async mounted() {
    await this.GET_EDUCATION_COURSES_TEST_EXAM()
    this.loadingPage = false;
  }
}
</script>

<style scoped>

</style>